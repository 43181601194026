/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import "./styles.css"

const Player = props => {
  const { playlist, slug, tag } = props

  console.log(slug, tag)

  const [trackIndex, setTrackIndex] = useState(0)
  const [mp3, setMp3] = useState(null)

  console.log(trackIndex)

  import(`/static/audio/${tag}/${slug}/audio${trackIndex}.mp3`)
    .then(obj => setMp3(obj.default))
    .catch(err => console.log(err))

  const handleClickPrevious = () => {
    setTrackIndex(currentTrack =>
      currentTrack === 0 ? playlist.length - 1 : currentTrack - 1
    )
  }

  const handleClickNext = () => {
    setTrackIndex(currentTrack =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    )
  }

  return (
    <>
      <AudioPlayer
        // style={{ width: "300px" }}
        style={{ backgroundColor: "#212121", fontSize: "1.5rem" }}
        className="text-kinzica-pink py-4"
        loop={false}
        autoPlay={false}
        // layout="horizontal"
        //src={playlist[trackIndex].src}
        src={mp3}
        onPlay={e => console.log("onPlay")}
        showSkipControls={true}
        showJumpControls={false}
        header={`${playlist[trackIndex].name}`}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        onEnded={handleClickNext}
        // other props here
      />
    </>
  )
}

export default Player
