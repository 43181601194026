/* This example requires Tailwind CSS v2.0+ */

import React, { useState, useEffect } from "react"

import "../../css/player.css"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../Layout/Layout"
import Seo from "../Layout/Seo"

import { graphql } from "gatsby"
import Player from "../Components/Player/Player"
import { PageTitle } from "../Components/Headings/PageTitle"

const AudioWorkDetails = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  console.log(data)

  const { html } = data.markdownRemark

  const { title, description, thumb, tag, type, slug, tracks } =
    data.markdownRemark.frontmatter

  const seoImage = data.markdownRemark.frontmatter.thumb.publicURL

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        url={"www.kinzicastudio.com/works/library-music/" + slug}
        keywords={[
          "Kinzica Studio Library music",
          "Kinzica Studio Sound desing works",
          "Kinzica studio",
        ]}
        image={seoImage}
      />
      <div className="bg-kinzica-dark px-4 py-4 mx-auto w-full lg:px-8 lg:py-0 lg:w-2/3 text-center">
        <PageTitle title={title} />

        {/* Player */}
        <div
          className="pt-16 flex lg:flex-row flex-col items-center justify-center 
				lg:space-x-16 space-x-0"
        >
          <div className="">
            <GatsbyImage
              image={getImage(thumb.childImageSharp.gatsbyImageData)}
              className="w-full mx-auto"
              alt=""
            />
          </div>
          <div className="my-4 lg:w-2/3 md:w-2/3 w-full">
            <Player slug={slug} tag={tag} playlist={tracks} />
          </div>
        </div>

        <div className="mx-auto mt-24 mb-24 w-full p-8 lg:px-8 lg:py-8 lg:w-2/3">
          <h1 className="mt-8 text-start text-4xl  text-white">
            Client:{" "}
            <span className="underline underline-offset-8">{title}</span>
          </h1>
          <p className="mt-2 text-start text-white text-2xl">
            Type: <span className="underline underline-offset-8">{type}</span>
          </p>
          <p className="mt-4 border-b-white border-b"></p>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            className="font-mont font-thin leading-snug
						mt-16 w-full mx-auto text-white text-3xl text-start"
          />
        </div>
      </div>
    </Layout>
  )
}

export default AudioWorkDetails

export const query = graphql`
  query AudioWorkDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        slug
        tag
        type
        title
        client
        collaborators
        date
        description
        tracks {
          name
          src
        }
        thumb {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
